import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Cta from '@components/content/Cta';
import Hero from '@components/content/hero/Hero';
import Image from '@components/utility/Image';
import Loadable from '@loadable/component';
//styles
import './styles.scss';

const WhizzbangLine = Loadable(() => import('@components/content/WhizzbangLine'));
const TableSafetyProfile = Loadable(() => import('@components/content/TableSafetyProfile'));

const SafetyProfile = () => {
	return (
		<Layout>
			<Seo title='Tolerability: Safety Profile | EMFLAZA® (deflazacort)' description='See safety information (including adverse reactions) from clinical trials of EMFLAZA® (deflazacort). View full Prescribing Information & Important Safety Information.' />
			<div className="md:container 2xl:mx-auto page--safety-profile xl:pr-48">
				<Hero heroImage='hero-3-1.png' heroImageMobile='hero-3-1-mobile.png' breadcrumbs={['Tolerability', 'Safety Profile']} title='Safety Profile' addedClass='py-2' />

				<div className="max-w-4xl px-8 text-emflaza-blue-600 md:px-0">

					{/* TABLE SECTION START */}
					<div>
						<h2 className='text-xl font-bold text-center uppercase md:text-2xl text-emflaza-blue-400'>
							Adverse reactions that occurred in ≥5% of <br /> deflazacort-treated patients
							and occurred more frequently than in placebo-treated patients<sup className='font-medium'>3</sup>
						</h2>
						<TableSafetyProfile />
						<ul className='bulleted-list'>
							<li className='flex'>
								<span>
									More boys taking deflazacort experienced cataracts compared with
									those taking prednisone (4.4% vs 1.6%). No cataracts were graded as serious adverse events.
								<sup>7</sup>
								</span>
							</li>
						</ul>
					</div>
					{/* TABLE SECTION END */}

					{/* SECTION START */}
					<div className="relative">
						<WhizzbangLine imageName='whizzbang-line-3.png' addedClass="-top-80 md:-top-20 -left-10 lg:-top-20 lg:-left-20 max-w-[150px]"/>
						<div className="absolute section-bg -left-8 -right-8 -top-56 md:top-0 md:left-0 md:right-auto z-negative">
							<Image imageName='section-3-1.png' imageNameMobile='section-3-1-mobile.png' imageNameTablet='section-3-1-mobile.png' alt="Playing like a 6-year-old"></Image>
						</div>

						<div className="section-inner mt-96 md:mt-28 mb-36 relative md:min-h-[40vh] xl:min-h-[70vh]">
							<h2 className='static text-xl font-bold xl:absolute md:w-80 md:text-2xl text-emflaza-blue-400'>
								Guidelines recommend deflazacort as first-line therapy when there
								are pre-existing weight and/or behavioral issues or switching to deflazacort
								when weight gain and/or behavioral issues persist with prednisone<sup className='font-medium'>5</sup>
							</h2>
							<span className='patient-image-alt absolute -top-12 right-0 max-w-[75px]'>Not an <br /> actual patient.</span>
						</div>
					</div>
					{/* SECTION END */}


					<div className="z-10 flex flex-wrap mb-8 space-y-5 md:space-y-0 md:space-x-5 mt-14">
						<Cta ctaUrl='/getting-started/prescription-start-form' iconUrl='cta-prescription.png' ctaText='Prescription Start Form' addedClass='cta flex-1'/>
						<Cta ctaUrl='/tolerability/weight-behavior' iconUrl='cta-person-icon.png' ctaText='Effects on weight & behavior' addedClass='cta flex-1' iconAddedClass='max-w-[67px]'/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default SafetyProfile;
